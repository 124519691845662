<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_correos"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Correos
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="card bg-white d-flex flex-fill">
                <div class="card-body">
                  <div class="form-group">
                    <label for="email">Correos</label>
                    <textarea
                      class="form-control"
                      rows="5"
                      cols="93"
                      v-model="form.email"
                      :class="
                        $v.form.email.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    ></textarea>
                  </div>
                  <div class="alert alert-default-warning col-md-12">
                    <h5>
                      <i class="icon fas fa-exclamation-triangle"></i> Atención!
                    </h5>
                    <ul>
                      <li>
                        Por favor ingresa los correos separados por
                        <strong>comas</strong>, por ejemplo:
                        <strong>prueba@gmail.com, correo@example.com</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="enviarCorreo()"
                v-show="!$v.form.$invalid"                
              >
                Enviar Correo
                <i class="fas fa-envelope-open-text"></i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
export default {
  name: "SolicitudesConductoresEmail",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      solicitud: {},
      form: {
        email: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
      },
    },
  },
  methods: {
    correoModal(solicitud) {
      this.solicitud = solicitud;
      this.form.email = null;
    },

    enviarCorreo() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hse/enviarEmailEvaluacion",
        data: { id: this.solicitud, emails: this.form.email },
      })
        .then((response) => {
          this.$refs.closeModal1.click();
          this.$parent.getIndex();
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {},
};
</script>
