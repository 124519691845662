<template>
  <div class="modal fade" id="modaL_pase" style="background-color: #00000082">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-frontera-top-left pt-2 pb-2">
          <h4 class="modal-title text-white">
            Pase Campo Solicitud #{{ solicitud.id }}
          </h4>
          <button
            type="button"
            class="close text-white"
            data-dismiss="modal"
            aria-label="Close"
            id="close-modal"
            ref="closeModal1"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="header_bg">
          <p class="text-white text-bold text-center">
            {{ solicitud.Nombres }}
          </p>
          <div class="header_content">
            <img
              :src="uri_docs + solicitud.link_foto"
              alt="Logo"
              width="100"
              class="img-circle elevation-1"
            />
            <img
              v-if="!solicitud.link_foto"
              src="/img/avatar.png"
              alt=""
              class="center"
            />
          </div>
        </div>
        <div class="modal-body">
          <div v-if="url_qr != null" class="text-center">
            <VueQr :text="url_qr" :size="200"></VueQr>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card card-info card-outline collapsed-card">
              <div class="card-header pt-2 pb-2">
                <h5 class="card-title">
                  <i class="fa fa-align-justify text-secondary"></i>
                  Información Solicitud
                </h5>
                <div class="card-tools">
                  <button
                    type="button"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    class="btn btn-tool"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6"
                    ><b>Fecha Fin Pase Campo:</b>
                  </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.fecha_fin_pase_campo }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6"> Empresa: </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.Empresa }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6"> Estado: </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.Estado }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6"> Activo: </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.Activo }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="card card-info card-outline collapsed-card">
              <div class="card-header pt-2 pb-2">
                <h5 class="card-title">
                  <i class="fa fa-align-justify text-secondary"></i>
                  Información Conductor
                </h5>
                <div class="card-tools">
                  <button
                    type="button"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    class="btn btn-tool"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6"
                    ><b>Nombres:</b>
                  </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.Nombres }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6"> Correo: </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.email }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6">
                    Categoría Licencia:
                  </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.CategoriaLic }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6">
                    Tipo Contrato:
                  </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.TipoContrato }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6">
                    Tipo Documento:
                  </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.TipoDocumento }}
                  </span>
                </div>
                <div class="row">
                  <label class="col-md-4 col-sm-4 col-xs-6">
                    Número Documento:
                  </label>
                  <span class="col-md-8 col-sm-8 col-xs-6 text-right">
                    {{ solicitud.numero_documento }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
export default {
  name: "PaseCampoModal",
  components: {
    VueQr,
  },
  data() {
    return {
      cargando: false,
      solicitud: {},
      url_qr: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getSolicitud(solicitud) {
      this.solicitud = solicitud;
      this.url_qr =
        "https://back.pegasofrontera.com/HsePaseCampo/" + this.solicitud.id;
    },
  },
};
</script>
<style>
.header_bg {
  height: 130px;
  width: 755px;
  background: #201747;
  background: linear-gradient(to bottom right, #29697a, #001871);
  box-shadow: 0px 10px 40px rgba(98, 140, 255, 0.5);
  border-radius: 5px 10px 30px 80px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.header_content {
  position: absolute;
  top: 100px;
  left: 340px;
}
</style>